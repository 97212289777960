import PositionOfResponsibilities from "./PositionOfResponsibilities";
import "./positionOfResponsibility.css";

const Portfolio = () => {
  const responsibilitiesData = [
    {
      position: "Co-Head",
      company: "College Website Team, CCET",
      time: "September 2022 - Present",
      pointers: [
        "Consistently enhancing SEO, timely UI/UX and Database updates led to a 80% boost in web traffic, ultimately driving college’s growth.",
      ],
    },
    {
      position: "Webmaster",
      company: "CCET ACM / ACM-W Student Chapter",
      time: "October 2021 - August 2023",
      pointers: [
        "Responsible for Designing and Coding a new Website for the Club and grew a team of 15 web development students alongside from beginners to proficient developers.",
      ],
    },
    {
      position: "Tapping and Calling Head (CSE)",
      company: "Training and Placement Cell, CCET",
      time: "January 2023 - Present",
      pointers: [
        "Acting as a bridge between Companies and College, responsible for Calling and Bringing a Diverse pool of Companies to the College for Campus Placement drives.",
      ],
    },
  ];

  return (
    <section id="responsibilities">
      <h5>Key Roles and Responsibilities</h5>
      <h2>Position of Responsibilities</h2>

      <div className="container responsibilities__container">
        {responsibilitiesData.map((data, index) => (
          <PositionOfResponsibilities key={index} {...data} />
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
