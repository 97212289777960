import "./projects.css";
import ACM from "../../assets/Projects/ccet-acm.png";
import AIRLINE from "../../assets/Projects/airline-reservation.png";
import Apratim from "../../assets/Projects/apratim.png";
import COMPUTATIONAL from "../../assets/Projects/computational-items.jpg";
import CRUDEOIL from "../../assets/Projects/crude-oil.png";
import DASSEN from "../../assets/Projects/dassen.jpg";
import EEG from "../../assets/Projects/eeg-classification.jpg";
import GIT from "../../assets/Projects/hacktoberfest.jpeg";
import JAVASCN from "../../assets/Projects/java-scn.png";
import PayOrb from "../../assets/Projects/payorb.png";
import TEMPANALYSIS from "../../assets/Projects/temperature-analysis.png";
import TODO from "../../assets/Projects/todo-list.png";
import SNAKE from "../../assets/Projects/snake-game.png";
import SPORTS from "../../assets/Projects/sports-analyzer.png";
import WEB from "../../assets/Projects/web-development.png";

const data = [
  {
    id: 1,
    image: PayOrb,
    title: "PayOrb",
    repo: "https://github.com/devashish-gupta09/Payorb",
    demo: "https://payorb.in/",
  },
  {
    id: 2,
    image: DASSEN,
    title: "Domain Adaptable SSL-based Extraction of Non-Residential built-up",
    repo: "https://github.com/devashish-gupta09/SIH",
    demo: "#",
  },
  {
    id: 3,
    image: JAVASCN,
    title: "Java Secured Communication Network",
    repo: "https://github.com/devashish-gupta09/Java-Secured-Communication-Network",
    demo: "#",
  },
  {
    id: 4,
    image: CRUDEOIL,
    title: "Analysis and Forecasting of Crude Oil Prices",
    repo: "https://www.kaggle.com/code/devashishgupta09/analysis-and-forecasting-of-crude-oil-prices",
    demo: "#",
  },
  {
    id: 5,
    image: Apratim,
    title: "Apratim - CCET's Cultural Fest",
    repo: "https://github.com/devashish-gupta09/Apratim",
    demo: "https://apratim-shit-client-l1u72maqx-sohamjaiswal10.vercel.app/",
  },
  {
    id: 6,
    image: TEMPANALYSIS,
    title: "Temperature Analysis using ARIMA Model",
    repo: "https://www.kaggle.com/code/devashishgupta09/temperature-analysis-using-arima-model",
    demo: "#",
  },
  {
    id: 7,
    image: EEG,
    title:
      "Classification of Alcoholic and Control groups’ mental state using EEG Machine",
    repo: "https://www.kaggle.com/code/devashishgupta09/eeg-data-analysis",
    demo: "#",
  },
  {
    id: 8,
    image: COMPUTATIONAL,
    title: "Computational Data Management System",
    repo: "https://github.com/devashish-gupta09/Computational-Data-Management-System",
    demo: "#",
  },
  {
    id: 9,
    image: ACM,
    title: "CCET ACM Website",
    repo: "https://github.com/devashish-gupta09/ccet-acm",
    demo: "https://ccet.acm.org/",
  },
  {
    id: 10,
    image: TODO,
    title: "To-do List App",
    repo: "https://github.com/devashish-gupta09/ToDo-List-React-App-",
    demo: "https://devashish-gupta09.github.io/ToDo-List-React-App-/",
  },
  {
    id: 11,
    image: GIT,
    title:
      "Hands-On Session on: Hack the October with Hacktoberfest- Let's Learn Git & Github Together",
    repo: "#",
    demo: "https://www.youtube.com/watch?v=X2DqUX2x0gI",
  },
  {
    id: 12,
    image: WEB,
    title: "Hands-On Session on: Introduction to Web Development and ReactJS",
    repo: "#",
    demo: "#",
  },
  {
    id: 13,
    image: SNAKE,
    title: "Snake Game",
    repo: "https://github.com/devashish-gupta09/Snake-Game",
    demo: "#",
  },
  {
    id: 14,
    image: SPORTS,
    title: "Sports Analyzer",
    repo: "https://github.com/devashish-gupta09/Sports-Analyzer",
    demo: "#",
  },
  {
    id: 15,
    image: AIRLINE,
    title: "Airline Reservation System",
    repo: "https://github.com/devashish-gupta09/Airline-Reservation-System-using-C-language",
    demo: "#",
  },
];

const Projects = () => {
  return (
    <section id="projects">
      <h5>My Recent Work</h5>
      <h2>Projects / Work</h2>

      <div className="container project__container">
        {data.map(({ id, image, title, repo, demo }) => {
          return (
            <article key={id} className="project__item">
              <div className="project__item-image">
                <img src={image} alt="img1" />
              </div>
              <h3>{title}</h3>
              <div className="project__item-cta">
                {repo === "#" ? null : (
                  <a
                    href={repo}
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Repository
                  </a>
                )}
                {demo === "#" ? null : (
                  <a
                    href={demo}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Link
                  </a>
                )}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
