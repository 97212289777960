import "./positionOfResponsibility.css";
import PropTypes from "prop-types";

const PositionOfResponsibilities = ({ position, company, time, pointers }) => {
  return (
    <article className="position-of-responsibilities">
      <div className="position-of-responsibilities__head">
        <h3>{position}</h3>
      </div>
      <div className="position-of-responsibilities-body">
        <p className="company">{company}</p>
        <p className="time">{time}</p>
        <ul className="pointers">
          {pointers.map((pointer, index) => (
            <li key={index}>{pointer}</li>
          ))}
        </ul>
      </div>
    </article>
  );
};

PositionOfResponsibilities.propTypes = {
  position: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  pointers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PositionOfResponsibilities;
