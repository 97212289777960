import "./about.css";
import ME from "../../assets/0010148-DEVASHISH-a.jpg";
// import { FaAward } from "react-icons/fa";
// import { FiUsers } from "react-icons/fi";
// import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>

        <div className="about__content">
          {/* <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years working Experience</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>300+ Clients Worldwide</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>10+ Completed Projects</small>
            </article>
          </div> */}

          {/* Education History Section */}
          <div className="about__education">
            {/* <h5>Education</h5> */}
            <article className="about__card">
              <h5>Chandigarh College of Engineering and Technology</h5>
              <small>B.E. in Computer Science Engineering: </small>
              <small>2020 - 2024 (9.07/10.0)</small>
            </article>

            <article className="about__card">
              <h5>Government Model Sr. Sec. School</h5>
              <small>Intermediate - Class XII CBSE: </small>
              <small>2018 - 2020 (93%)</small>
            </article>

            <article className="about__card">
              <h5>Sri Guru Harkrishan Model School</h5>
              <small>Matriculation - CBSE: </small>
              <small>2018 (88.2%)</small>
            </article>
          </div>

          <p>
            I am a motivated computer science undergrad with a passion for
            technology. I have a strong academic background and experience in
            web development, machine learning, and data science. Proficient in
            C, C++, Python and Java, I am organized, efficient, and skilled in
            problem-solving. I work well independently and as a team player.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let&apos;s Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
