import { HiArrowSmRight } from "react-icons/hi";
import "./workExperience.css";

const Experience = () => {
  return (
    <section id="workexperience">
      <h5>Explore My Professional Journey</h5>
      <h2>Work Experience</h2>

      <div className="container workexperience__container">
        {/* Work Experience item 1 */}
        <div className="workexperience__item">
          <h3>Perspective Tech</h3>
          <h4>Software Engineering Intern</h4>
          <p>Duration: November 2021 - April 2022</p>
          <ul className="workexperience__pointers">
            <li>
              <HiArrowSmRight className="workexperience__details-icon" />
              <span className="workexperience__pointer-text">
                Created Payorb, a NextJS web app featuring React components,
                highlighting proficiency in web development.
              </span>
            </li>
            <li>
              <HiArrowSmRight className="workexperience__details-icon" />
              <span className="workexperience__pointer-text">
                Integrated secure Google authentication, Firebase backend
                services, Google Calendar integration, and the Zoom API to
                optimize user efficiency and increase website revenue.
              </span>
            </li>
          </ul>
        </div>

        {/* Work Experience item 2 */}
        <div className="workexperience__item">
          <h3>Design and Innovation Centre, PU</h3>
          <h4>Research Intern</h4>
          <p>Duration: November 2021 - February 2022</p>
          <ul className="workexperience__pointers">
            <li>
              <HiArrowSmRight className="workexperience__details-icon" />
              <span className="workexperience__pointer-text">
                Conducted thorough research and analysis on machine data to
                identify key features.
              </span>
            </li>
            <li>
              <HiArrowSmRight className="workexperience__details-icon" />
              <span className="workexperience__pointer-text">
                Developed predictive maintenance solutions using LSTM-RNN models
                and Thermal/Time Series analysis techniques, enhancing accuracy
                and efficiency in fault prediction.
              </span>
            </li>
          </ul>
        </div>

        {/* Work Experience item 3 */}
        <div className="workexperience__item">
          <h3>Insights2InfoTech</h3>
          <h4>Data Science Intern</h4>
          <p>Duration: November 2021 - February 2022</p>
          <ul className="workexperience__pointers">
            <li>
              <HiArrowSmRight className="workexperience__details-icon" />
              <span className="workexperience__pointer-text">
                Conducted web crawling to extract data from multiple websites.
              </span>
            </li>
            <li>
              <HiArrowSmRight className="workexperience__details-icon" />
              <span className="workexperience__pointer-text">
                Established automated data pipelines for preprocessing and
                analysis of extracted data.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Experience;
