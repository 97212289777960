import "./achievements.css";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";

const data = [
  {
    name: "Outstanding Chapter Website Award | ACM India",
    review:
      "Won the ACM India Outstanding Chapter Website Award for the CCET ACM Student Chapter Website, outperforming 200+ college chapters across India.",
  },
  {
    name: "Finalist Smart India Hackathon 2022",
    review:
      "Finalist in Smart India Hackathon 2022, Asia’s Largest Hackathon, outperforming 1000+ teams.",
  },
  {
    name: "ACM India Summer School 2023 | Algorithms in Data Science",
    review:
      "Selected as one of the Top 40 candidates for ACM India Summer School from 1000+ applicants.",
  },
  {
    name: "Special Jury Award, Netathon 2023",
    review:
      "Won special jury award created the community management application out of 20 teams of professional employees.",
  },
  {
    name: "SysCom 2021",
    review:
      "Published Research Paper titled Assess and Analysis Covid-19 Immunization Process: A Data Science Approach to make India self-reliant and safe in International Conference on Smart Systems and Advanced Computing 2021.",
  },
  {
    name: "3rd Prize | IPD EXPO 2022 ",
    review:
      "Won 3rd Prize in the Annual IPD EXPO 2022 organized by CCET, demonstrating excellence in project development and presentation skills",
  },
  {
    name: "Special Mention | Code Blooded 4.0",
    review:
      "Received Special Mention in the 'Code Blooded 4.0', a 24-hour Hackathon organized by PEC ACM-CSS and STGI Technologies Consulting Pvt Ltd, acknowledging exceptional coding skills and contribution to the event.",
  },
];

const Achievements = () => {
  return (
    <section id="achievements">
      <h5>Success Snapshot</h5>
      <h2>Achievements</h2>

      <Swiper
        className="container achievements__container mySwiper"
        modules={[Autoplay, Keyboard, Pagination]}
        centeredSlides={true}
        keyboard={{
          enabled: true,
        }}
        spaceBetween={40}
        slidesPerView={2}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          300: {
            slidesPerView: 1,
          },
          800: {
            slidesPerView: 2,
          },
        }}
      >
        {data.map(({ name, review }, index) => {
          return (
            <SwiperSlide key={index} className="achievement">
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Achievements;
