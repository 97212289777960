import About from "./components/about/About";
import Achievements from "./components/achievements/Achievements";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import PositionOfResponsibility from "./components/position-of-responsibility/PositionOfResponsibility";
import Projects from "./components/projects/Projects";
import Skills from "./components/skills/Skills";
import WorkExperience from "./components/work-experience/WorkExperience";

import AnimatedCursor from "react-animated-cursor";

// import { Circle2 } from "react-preloaders";

function App() {
  return (
    <>
      <Header />
      <Nav />
      <About />
      <Skills />
      <WorkExperience />
      <Projects />
      <PositionOfResponsibility />
      <Achievements />
      <Contact />
      <Footer />
      {/* <Circle2 background="#1c1c33" color="#fff" /> */}
      <AnimatedCursor
        innerSize={8}
        outerSize={35}
        innerScale={1}
        outerScale={2}
        outerAlpha={0}
        hasBlendMode={true}
        innerStyle={{
          backgroundColor: "#fff",
        }}
        outerStyle={{
          border: "3px solid #fff",
        }}
      />
    </>
  );
}

export default App;
