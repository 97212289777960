import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { FaOrcid } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a
        href="https://www.linkedin.com/in/devashish-gupta-066a16200/"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/devashish-gupta09/"
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub />
      </a>
      <a
        href="https://leetcode.com/devashishgupta9822/"
        target="_blank"
        rel="noreferrer"
      >
        <SiLeetcode />
      </a>
      <a
        href="https://orcid.org/0000-0001-5288-6577/"
        target="_blank"
        rel="noreferrer"
      >
        <FaOrcid />
      </a>
    </div>
  );
};

export default HeaderSocials;
